/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, m, Variants } from 'framer-motion';
import { useClearRefinements, useCurrentRefinements, useHits } from 'react-instantsearch';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Button, Close, Container, Flex, FrameShapeSelect, Paragraph, RefinementChip, Spacer, Title } from '@components';
import { filterRefinements, FRAME_MATERIALS, FRAME_SHAPES, normalizeCollectionRefinement } from '@utils/algolia';
import { useFilterContext } from '@utils/context/Filter';
import { useActiveFrame, useLocalStorage, useLockedBody } from '@utils/hooks';
import { acetylateMetalColors, capitalize } from '@utils/strings';
import variables from '@styles/export.module.scss';
import { useCustomer } from '@services/shopify';
import { FRAME_COLORS } from '@constants';
import styles from './FilterAccordionPanel.module.scss';

const variants: Variants = {
	open: () => ({
		top: `calc(${variables.headerHeight})`,
		x: 0,
		width: '100%',
	}),
	closed: () => ({
		top: '150%',
		x: 0,
		width: '100%',
	}),
};

const FilterAccordionPanel = ({ children, useFramePopup = false }) => {
	const { results } = useHits();
	const { items, refine } = useCurrentRefinements();
	const { refine: clearRefinements } = useClearRefinements();
	const isColorRefresh = useFeatureIsOn('is-color-refresh-2');
	const { isLoading } = useCustomer();
	const [, setHasSetDefaultFrame] = useLocalStorage<boolean>('hasSetDefaultFrame', false);
	const [, setActiveFrame] = useActiveFrame();
	const [open, setOpen] = useState(false);

	const {
		collections,
		frameShape,
		updateFrameShape,
		isOpen,
		setIsOpen,
		isMobile,
		scrollToTop,
		type,
		showFrameSelector,
		isHeaderBigger,
		setFrameShape,
		setPriceFilter,
	} = useFilterContext();
	const isBFAllTops = type === 'bf-all-tops';
	const currentRefinements = items.map(index => {
		const { refinements: originalRefinements } = index;
		const filteredRefinements = filterRefinements(originalRefinements);
		return filteredRefinements.map(item => {
			const { value, label, attribute } = item;
			const normalizedLabel = label
				.replace(/\["|"\]+/g, '')
				.replace(/_/g, ' ')
				.replace(/Sun -\s*/g, '');
			let formattedLabel = capitalize(FRAME_SHAPES[normalizedLabel] || normalizedLabel, true);
			if (attribute === 'tags' && FRAME_MATERIALS[label]) {
				formattedLabel = FRAME_MATERIALS[label];
			}
			if (Object.values(FRAME_COLORS).includes(label)) {
				formattedLabel = acetylateMetalColors(formattedLabel, isColorRefresh);
			}
			if (attribute === 'product_type' || attribute === 'option_names') {
				formattedLabel = collections && normalizeCollectionRefinement(collections, label);
			}
			return (
				<RefinementChip key={value} refine={refine} items={originalRefinements} item={item}>
					{formattedLabel}
				</RefinementChip>
			);
		});
	});
	const handleClearRefinements = () => {
		clearRefinements();
		setPriceFilter(null);
	};

	useLockedBody(isOpen && isMobile);

	// TODO: move FilterAccordionPanel to a Modal or Drawer so that z-index is easier to control
	useEffect(() => {
		const adaWidgetContainer = document && document.getElementById('ada-entry');

		if (isMobile && isOpen) {
			adaWidgetContainer && adaWidgetContainer.setAttribute('style', 'display: none;');
		} else {
			adaWidgetContainer && adaWidgetContainer.setAttribute('style', 'display: initial;');
		}
	}, [isMobile, isOpen]);

	useEffect(() => {
		if (!isLoading) {
			const value = window.localStorage.getItem('hasSetDefaultFrame');
			const hasSetDefaultFrame = !!value && JSON.parse(value);
			setOpen(!hasSetDefaultFrame);
		}
	}, [isLoading]);

	const desktopContainer = cn(styles['container--no-animation'], {
		[styles['container--all-tops']]: type === 'all-tops' && isHeaderBigger,
		[styles['container--bf-all-tops']]: isBFAllTops,
		[styles['container--hidden']]: !isOpen,
	});

	const frameSelectorMemo = useMemo(() => {
		return (
			<div
				className={cn({
					[styles['search-frame-selector']]: type === 'search',
					[styles['search-hidden']]: !showFrameSelector,
				})}
			>
				<Paragraph>Frame Shape:</Paragraph>
				<FrameShapeSelect
					callback={updateFrameShape}
					buttonProps={{
						fullWidth: true,
						color: 'transparent-dark',
						withChevron: true,
						chevronDirection: 'down',
						label: frameShape,
						spaceBetween: true,
						['data-testid']: 'frame-shape-select',
					}}
				/>
			</div>
		);
	}, [frameShape, showFrameSelector]);

	const filterPanelDesktop = (
		<>
			<div className={desktopContainer}>
				<Flex column style={{ maxHeight: 'auto' }} fullWidth>
					{type != 'search' && !isBFAllTops && frameSelectorMemo}
					{currentRefinements.length > 0 ? (
						<div>
							<Flex style={{ padding: '1.6rem 0', flexWrap: 'wrap', gap: '0.8rem' }}>{currentRefinements}</Flex>
							<Button
								linkStyle
								fitContent
								size='small'
								label='Clear All Filters'
								onClick={() => {
									scrollToTop();
									handleClearRefinements();
								}}
							/>
						</div>
					) : null}
					{type === 'search' && frameSelectorMemo}
					<Container>{children}</Container>
				</Flex>
			</div>
		</>
	);

	return (
		<>
			{useFramePopup && (
				<FrameShapeSelect
					isPopupFrameSizing
					open={open}
					onOpenChange={() => setOpen(!open)}
					callback={newFrame => {
						newFrame && setActiveFrame(newFrame);
						newFrame && setFrameShape(newFrame);
						setHasSetDefaultFrame(true);
					}}
				/>
			)}
			{isMobile ? (
				<>
					<m.div
						className={styles['container']}
						variants={variants}
						initial={'closed'}
						animate={isOpen ? 'open' : 'closed'}
						transition={{ duration: 0.5, type: 'spring' }}
						exit={'closed'}
						custom={isMobile}
					>
						<Flex justify='between' align='start' className={styles['header']}>
							<Title>Filter</Title>
							<Close height={15} label='Close Filter' onClick={() => setIsOpen(false)} />
						</Flex>
						<Flex column style={{ maxHeight: 'auto' }} fullWidth>
							<Flex column className={!isBFAllTops && styles['current-refinements']}>
								<AnimatePresence>
									{items[0]?.refinements && (
										<m.div
											initial={{ opacity: 0, height: 0 }}
											animate={{ opacity: 1, height: 'auto' }}
											transition={{ type: 'spring', stiffness: 200, damping: 24, duration: 0.2 }}
											exit={{ opacity: 0, height: 0 }}
										>
											<Flex style={{ padding: '1.6rem 0', flexWrap: 'wrap', gap: '0.8rem' }}>
												{currentRefinements}
											</Flex>
											<Button
												linkStyle
												fitContent
												size='small'
												label='Clear All Filters'
												onClick={() => {
													scrollToTop();
													handleClearRefinements();
												}}
											/>
										</m.div>
									)}
								</AnimatePresence>

								{!isBFAllTops && (
									<>
										<Spacer size='1.6rem' />
										{frameSelectorMemo}
									</>
								)}
							</Flex>
							<Container style={{ marginBottom: '6.8rem' }}>{children}</Container>
						</Flex>
						{isOpen && (
							<Flex justify='around' className={styles['buttons']} gap={3}>
								<Button color='white' label='Clear Filters' onClick={() => handleClearRefinements()} fullWidth />
								<Button
									color='blue'
									label={`View Products (${results?.nbHits})`}
									onClick={() => setIsOpen(false)}
									fullWidth
									data-view-products
								/>
							</Flex>
						)}
					</m.div>
					{isOpen && <div className={cn(styles['filter-overlay'], { [styles['filter-overlay-open']]: isOpen })} />}
				</>
			) : (
				filterPanelDesktop
			)}
		</>
	);
};

export default FilterAccordionPanel;
