/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { forwardRef, MutableRefObject, useReducer, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Script from 'next/script';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { useBaseFrameProducts } from '@services/shopify';
import { Chevron, Flex, InteractiveCard, Loading, Modal, Tabs, TypographyButton, VerticalCard, VTO } from '@components';
import { capitalize, getBaseName } from '@utils/strings';
import { FRAME_COLORS, LIMITED_COLORS, VTO_SRC } from '@constants';
import { baseFrameReducer, BaseFrameState, BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import { NormalizedProduct } from '@ts/product';
import { fetchContentful } from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers';
import { DemoListFields } from '@ts/index';
import ComponentHeader from '../ComponentHeader';
import styles from './TabCarousel.module.scss';
import { useTranslation } from '@utils/index';

type TabCarouselProps = {
	data: NormalizedProduct[];
	demoLists: Record<string, DemoListFields>;
	title: string;
	subtitle: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FitMix: any;
	activeTab: 'Eyeglasses' | 'Sunglasses';
};

type TabCarouselContentProps = {
	data: NormalizedProduct[];
	demoLists: Record<string, DemoListFields>;
	setVtoState: React.Dispatch<BaseFrameStateDispatch>;
	frameType: 'eyeglasses' | 'sunglasses';
};

const getTranslatedTexts = translator => {
	return {
		eyeglasses: translator('eyeglasses'),
		sunglasses: translator('sunglasses'),
		exploreTheBase: (xx: string) => translator('explore-the-base', { xx }),
		shopProduct: (productName: string) => translator('shop-product', { productName }),
	};
};

// Wrap the whole thing in an HOC to handle the data fetching, otherwise inital VTO Reducer state is wonky
const TabCarousel = props => {
	const { locale } = useRouter();
	const [activeTab, setActiveTab] = useState('Eyeglasses');
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	// we'll need to pass this in, leaving hard-coded for now until we've run the test
	const { data, isLoading } = useBaseFrameProducts();

	const { data: demoListData, isLoading: demoListLoading } = useQuery(['demoList'], async () => {
		const initialData = await Promise.allSettled([
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'women',
				'limit': 1,
				'include': 5,
				locale,
			}),
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'men',
				'limit': 1,
				'include': 5,
				locale,
			}),
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'kids',
				'limit': 1,
				'include': 5,
				locale,
			}),
		]);

		const [womenList, menList, kidsList] = initialData
			.map(result => (result.status === 'fulfilled' ? result.value : null))
			.filter(Boolean);

		const normal = await Promise.allSettled([
			normalizeContentfulEntry(womenList),
			normalizeContentfulEntry(menList),
			normalizeContentfulEntry(kidsList),
		]);

		const [normalizedWomen, normalizedMen, normalizedKids] = normal
			.map(result => (result.status === 'fulfilled' ? result.value : null))
			.filter(Boolean);

		return {
			women: normalizedWomen,
			men: normalizedMen,
			kids: normalizedKids,
		};
	});

	return (
		<>
			<Modal>
				<Tabs
					data-tab-carousel
					value={activeTab}
					onValueChange={value => setActiveTab(value)}
					activationMode='manual'
					className={styles.container}
				>
					<ComponentHeader className={styles.homepagePadding} title={props.title} subtitle={props.subtitle}>
						<Tabs.List>
							<Tabs.Trigger value='Eyeglasses' data-tab-carousel-tab={'eyeglasses'}>
								<TypographyButton>{translations.eyeglasses}</TypographyButton>
							</Tabs.Trigger>
							<Tabs.Trigger value='Sunglasses' style={{ margin: 0 }} data-tab-carousel-tab={'sunglasses'}>
								<TypographyButton>{translations.sunglasses}</TypographyButton>
							</Tabs.Trigger>
						</Tabs.List>
					</ComponentHeader>
					{isLoading || demoListLoading ? (
						<div className={styles.loadingWrapper}>
							<Loading />
						</div>
					) : (
						<DataFetching
							data={data?.slice(0, 4)}
							demoLists={demoListData}
							activeTab={activeTab}
							FitMix={FitMix}
							{...props}
						/>
					)}
				</Tabs>
			</Modal>
			<Script
				src={VTO_SRC}
				strategy='lazyOnload'
				type='text/javascript'
				onLoad={() => {
					setFitMix(window.FitMix);
				}}
			></Script>
		</>
	);
};

// This is the actual component that will be rendered
const DataFetching = ({ data, demoLists, FitMix, activeTab }: TabCarouselProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const isHideNewColors = useFeatureIsOn('is-hide-new-products');

	const filteredData = data
		.map(product => ({
			...product,
			variants: product.variants.filter(v => !LIMITED_COLORS.includes(v.option as FRAME_COLORS)),
		}))
		.filter(product => product.variants.length > 0);

	const dataToShow = isHideNewColors ? filteredData : data;

	const initialVtoState: BaseFrameState = {
		product: dataToShow[0],
		variant: dataToShow[0].variants[0],
		frame: dataToShow[0].variants[0].option as FRAME_COLORS,
		lens: null,
		image: dataToShow[0].variants[0].image,
	};

	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);

	return (
		<>
			<Tabs.Content value='Eyeglasses' removeDefaultStyles>
				<TabCarouselContent
					data={dataToShow}
					demoLists={demoLists}
					setVtoState={setVtoState}
					frameType='eyeglasses'
					ref={ref}
				/>
			</Tabs.Content>
			<Tabs.Content value='Sunglasses' removeDefaultStyles>
				<TabCarouselContent
					data={dataToShow}
					demoLists={demoLists}
					setVtoState={setVtoState}
					frameType='sunglasses'
					ref={ref}
				/>
			</Tabs.Content>
			<Modal.Content
				removePadding
				includeCloseButton={false}
				onCloseAutoFocus={e => e.preventDefault()}
				style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
			>
				<VTO
					FM={FitMix}
					state={vtoState}
					setState={setVtoState}
					products={dataToShow}
					pathInfo={`${activeTab.toLowerCase()}/all`}
				/>
			</Modal.Content>
		</>
	);
};

// Content for each Tab is identical on a component level except for sunglass/eyeglass differences, so using one component keeps this file smaller
const TabCarouselContent = forwardRef<HTMLDivElement, TabCarouselContentProps>(
	({ data, setVtoState, demoLists, frameType }, ref) => {
		const sunglasses = frameType === 'sunglasses';
		const { translator } = useTranslation();
		const translations = getTranslatedTexts(translator);

		const handleClick = direction => {
			if (direction === 'left') {
				(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: -180, behavior: 'smooth' });
			} else {
				(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: 180, behavior: 'smooth' });
			}
		};

		return (
			<>
				<div ref={ref} className={styles.content} data-tab-carousel-content={frameType}>
					{data.map(p => (
						<VerticalCard
							key={p.handle}
							product={p}
							variant={p.variants[0]}
							showTags={false}
							primaryAction='view'
							secondaryAction={sunglasses ? null : 'vto'}
							buttonGroupType='stacked'
							showVariantControls
							showLensController={sunglasses}
							label={translations.exploreTheBase(getBaseName(p.name, false))}
							dispatch={sunglasses ? null : setVtoState}
							data-tab-carousel-card={frameType}
							dataTags={{
								button: {
									[`data-tab-carousel-card-${frameType}-cta`]: getBaseName(p.name),
								},
							}}
						/>
					))}
				</div>
				<Flex gap={3} justify='end' className={styles.buttonContainer}>
					<div className={styles.button} onClick={() => handleClick('left')}>
						<Chevron direction='left' />
					</div>
					<div className={styles.button} onClick={() => handleClick('right')}>
						<Chevron direction='right' />
					</div>
				</Flex>
				<div className={styles.cards} data-tab-carousel-ctas={frameType}>
					{Object.entries(demoLists).map(([key, value]) => (
						<InteractiveCard
							key={key}
							background={value[sunglasses ? 'sunglassesHero' : 'hero'].imageMobile.url}
							title=''
							shouldExpand={false}
							size='micro'
							data-tab-carousel-cta={key}
							button1={{
								label: translations.shopProduct(capitalize(key)),
								link: `${frameType}/${key.toLowerCase()}`,
								color: 'transparent-light',
							}}
						/>
					))}
				</div>
			</>
		);
	}
);

TabCarouselContent.displayName = 'TabCarouselContent';

export default TabCarousel;
