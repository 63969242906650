import { Fragment, useMemo, useState, useEffect } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Flex, Heading, InformationIcon, Paragraph } from '@components';
import { PrescriptionTableColumn, transformPrescription } from '@ts/rxss';
import variables from '@styles/export.module.scss';
import { useRXSSContext } from '@context';
import styles from './Review.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		rxssWarningMessage: translator('rxss-warning-message'),
		rightEyeOd: translator('right-eye-od'),
		leftEyeOs: translator('left-eye-os'),
		pupillaryDistance: translator('pupillary-distance'),
		reviewPrescriptionDetails: translator('review-prescription-details'),
		submitIfAllGood: translator('submit-if-all-good'),
	};
};

const getColumns = (translations: Record<string, string>) => {
	const columns: ColumnDef<PrescriptionTableColumn>[] = [
		{
			accessorKey: 'option',
			cell: info => {
				const option = (info.getValue() ?? {}) as PrescriptionTableColumn['option'];
				return (
					<>
						<Paragraph>{option.title}</Paragraph>
						{option.subtitle && <Paragraph className={styles.subtitle}>{option.subtitle}</Paragraph>}
					</>
				);
			},
			header: () => <span />,
		},
		{
			accessorKey: 'left',
			cell: info => {
				let text = info.renderValue() as PrescriptionTableColumn['left'];
				text ||= 'N/A';
				return <Paragraph className={styles.cell}>{text}</Paragraph>;
			},
			header: () => <Paragraph>{translations.rightEyeOd}</Paragraph>,
		},
		{
			accessorKey: 'right',
			cell: info => {
				let text = info.renderValue() as PrescriptionTableColumn['right'];
				text ||= 'N/A';
				return <Paragraph className={styles.cell}>{text}</Paragraph>;
			},
			header: () => <Paragraph>{translations.leftEyeOs}</Paragraph>,
		},
	];
	return columns;
};

const Review = () => {
	const { selectedFields, hasTwoPds, uploadResult, formData } = useRXSSContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const columns = getColumns(translations);

	const selectedFieldsWithPD = useMemo(() => {
		if (!selectedFields.includes('pd')) {
			return [...selectedFields, 'pd'];
		}
		return selectedFields;
	}, [selectedFields]);

	const [data, setData] = useState(() => []);

	const table = useReactTable({
		data,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
	});

	useEffect(() => {
		const tableData =
			transformPrescription({
				formData,
				selectedFields: selectedFieldsWithPD,
				hasTwoPds,
				warnings: uploadResult?.warnings ?? [],
				errors: uploadResult?.errors ?? [],
			}) ?? [];

		setData(tableData);
	}, [formData, hasTwoPds, selectedFieldsWithPD, uploadResult]);

	const singlePdTableRow = useMemo(
		() =>
			!hasTwoPds && (
				<tr>
					<td className={styles.label}>
						<Paragraph>{translations.pupillaryDistance}</Paragraph>
					</td>
					<td colSpan={2}>
						<Paragraph className={styles.cell}>{formData['single_pd']}</Paragraph>
					</td>
				</tr>
			),
		[hasTwoPds, formData]
	);

	return (
		<div className={styles['container']}>
			<Flex className={styles['heading']} column>
				<Heading tag='h6'>{translations.reviewPrescriptionDetails}</Heading>
				<Paragraph>{translations.submitIfAllGood}</Paragraph>
			</Flex>
			<div className={styles['table-container']}>
				<Flex className={styles.table} fullWidth>
					<table>
						<thead>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => (
										<th key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<Fragment key={row.id}>
									<tr>
										{row.getVisibleCells().map((cell, index) => {
											if (typeof cell.getValue() === 'undefined') return null;

											return (
												<td key={cell.id} rowSpan={!index && !!row.original.warning ? 2 : 1} colSpan={1}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											);
										})}
									</tr>
									{row.original.warning && (
										<tr data-row-type='warning' data-rxss-warning={row.original.option.title.toLowerCase()}>
											<td colSpan={3} className={styles.warning}>
												<Flex>
													<InformationIcon color={variables.yellow4} className={styles.icon} />
													<Paragraph style={{ fontWeight: 500 }}>
														{translations.rxssWarningMessage}
													</Paragraph>
												</Flex>
											</td>
										</tr>
									)}
								</Fragment>
							))}
							{singlePdTableRow}
						</tbody>
					</table>
				</Flex>
			</div>
		</div>
	);
};

export default Review;
